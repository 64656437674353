
import './vote.css';

export const CastVoteButtonGroup = ({ handleUpvote, handleDownvote, disabled }) => {
  return (
    <div className="btn-group">
      <UpvoteButton disabled={disabled} onClick={handleUpvote} />
      <DownvoteButton disabled={disabled} onClick={handleDownvote} />
    </div>
  )
}

export const UpvoteTriangle = () => {
  return (
    <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.178 1.18645C12.5757 0.612441 13.4243 0.612441 13.822 1.18645L24.903 17.1805C25.3625 17.8437 24.8878 18.75 24.081 18.75H1.91899C1.11217 18.75 0.637517 17.8437 1.097 17.1805L12.178 1.18645Z" fill="#B4D49B" />
    </svg>
  )
}

export const DownvoteTriangle = () => {
  return (
    <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0136 17.5763C11.4908 18.2651 12.5092 18.2651 12.9864 17.5763L23.6855 2.13339C24.2369 1.33755 23.6673 0.25 22.6991 0.25H1.30087C0.332682 0.25 -0.236903 1.33755 0.314472 2.13339L11.0136 17.5763Z" fill="#DF9999" />
    </svg>
  )
}

export const UpvoteButton = ({ onClick, disabled }) => {
  return (
    <button className='vote-button' onClick={onClick} disabled={disabled}>
      <UpvoteTriangle />
    </button>
  )
}

export const DownvoteButton = ({ onClick, disabled }) => {
  return (
    <button className='vote-button' onClick={onClick} disabled={disabled}>
      <DownvoteTriangle />
    </button>
  )
}