import { useEffect, useState } from 'react';
import { useConnect } from 'wagmi'
import Button from '../core/button';
import ScaleLoader from "react-spinners/ScaleLoader";
import './signIn.css';
import TwitterIconBlack from '../core/twitterIcon';

export function ConnectWallet({ nextState }) {
  const [wallet, setWallet] = useState();
  const [error, setError] = useState();
  const [{ data: connectData, error: connectError }, connect] = useConnect();

  const handleConnect = async (provider) => {
    try {
      await connect(provider);
      setWallet(provider);
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!connectData || !connectData.connected || !wallet) {
      return;
    }
    nextState();
    localStorage.setItem('provider', wallet.name);
  }, [connectData, wallet, nextState])

  useEffect(() => {
    if (!connectError) { setError(undefined); return; }
    if (connectError?.message && connectError.message.startsWith('Already processing eth_requestAccounts')) {
      setError('Please unlock your wallet.')
    } else if (connectError?.message) {
      setError(connectError.message)
    } else if (connectError) {
      setError('Failed to connect');
    }
  }, [connectError])

  return (
    <>
      <div className="font-mono font-bold text-center py-3.5 bg-gray-wash text-gray-secondary border-b border-gray-detail font-bold">
        Connect Wallet
      </div>
      <div className="block pt-7 pb-4 px-8 bg-gray-50 text-center">
        {connectData.connectors.map((provider) => {
          if (!provider.ready) { return <></> }
          return (
            <div key={provider.id}>
              <button
                className='wallet-connect'
                disabled={!provider.ready} onClick={() => handleConnect(provider)}
              >
                {provider.name}
              </button>
            </div>
          )
        })}
        {error && <div>{error}</div>}
      </div>
    </>
  )
}

export function Sign({ handleSubmit, onSubmit, register, loading }) {
  const [{ data: connectData }] = useConnect();
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full font-body pb-4">
        <div className="w-full font-mono font-bold text-center py-3.5 bg-gray-wash text-gray-secondary border-b border-gray-detail font-bold">
          Verify your wallet with Referents
        </div>
        <div className="pt-7 pb-4 px-8 bg-gray-50">
          <p className="font-mono pb-3.5 text-center">To interact with this artifact, please verify your identity via Twitter. You will need to sign a message (gasless).</p>
          <p className="font-mono text-brown-20 mt-4">Please enter your name and Twitter handle to verify:</p>
          <div className="mt-2">
            <input
              className="font-mono rounded-t-lg border border-gray-detail focus:outline-none w-full px-4 py-4"
              type="text" {...register("name")}
              autoComplete="off"
              autoFocus placeholder="Your name or alias; this will be displayed with your contributions"
            />
            <input
              className="font-mono rounded-b-lg border-b border-l border-r border-gray-detail focus:outline-none w-full px-4 py-4"
              type="text"{...register("handle")}
              autoComplete="off"
              placeholder="Your Twitter handle for verification; this will not be displayed in Referents"
            />
          </div>
          <div className="mt-2 text-center">
            <Button
              disabled={!connectData.connected}
              className='sign-button'
              type="submit"
            >
              {loading ? <ScaleLoader color="gray" /> : `Sign`}
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}

const generateTweet = (sig) => {
  const str = `I am verifying for Referents, a linguistic resource made in the ethos of web3. signature:${sig} \n\ncoming soon @verses_xyz`;
  window.open(`https://twitter.com/intent/tweet?text=${encodeURI(str)}`);
}

export function SocialProof({ prevState, nextState, formData, setDisplayedError, displayedError, loading, setIsLoading, signWithoutVerifying }) {
  const [missingHandle, setMissingHandle] = useState(false);

  const onlySign = () => {
    setIsLoading(true)
    signWithoutVerifying()
      .then(() => {
        nextState()
        setIsLoading(false);
      })
      .catch((err) => {
        setDisplayedError(err.message);
        setIsLoading(false);
      })
  }

  return (
    <>
      <div className="w-full font-mono font-bold text-center py-3.5 bg-gray-wash text-gray-secondary border-b border-gray-detail font-bold">
        Confirm your signature
      </div>
      <div className="pt-7 pb-4 px-8">
        <p className="font-mono text-center">
          Tweet a message to prove that you control this address. Return to this page afterwards to complete verification.
        </p>
        <div className="mt-2 text-center">
          <Button
            onClick={() => {
              setDisplayedError('');
              if (formData.handle === "") {
                setDisplayedError("Please add a handle to verify on Twitter.")
                setMissingHandle(true);
                return
              }
              generateTweet(formData.sig)
              setIsLoading(false);
              nextState();
            }}>
            <TwitterIconBlack />
            Post proof
          </Button>
        </div>
        {(missingHandle || displayedError) &&
          <div>
            <div className='text-center my-4'>
              <button className="font-mono underline font-light text-gray-400"
                onClick={onlySign}>
                {loading ? <ScaleLoader color="gray" height={12} width={3} /> : 'Sign without verifying'}
              </button>
            </div>
            <div className='text-center my-4'>
              <button className="font-mono underline font-light text-gray-400"
                onClick={prevState}
              >
                Go Back
              </button>
            </div>
          </div>}
      </div>
    </>
  )
}

export function VerifyTwitter({ prevState, loading, handleTwitterVerifyAndSign, displayedError }) {
  return (
    <>
      <div className="w-full font-mono font-bold text-center py-3.5 bg-gray-wash text-gray-secondary border-b border-gray-detail font-bold">
        Complete verification
      </div>
      <div className="pt-7 pb-4 px-8 text-center">
        <p className="font-mono">
          After sending your tweet, click the button below to complete verification:
        </p>
        <div className="mt-5 mb-3 text-center">
          <Button
            onClick={handleTwitterVerifyAndSign}
          >
            {loading ? <ScaleLoader color="gray" height={12} width={3} /> : 'Verify tweet'}
          </Button>
        </div>
        {displayedError && <div className="text-center">
          <button
            className="font-mono underline font-light text-gray-400"
            onClick={prevState}
          >
            Back
          </button>
        </div>}
      </div>
    </>
  )
}

const generateSocial = () => {
  const str = `Referents is a glossary for the Web3 community, and a linguistic resource made in the ethos of Web3. Drop by @verses_xyz`;
  window.open(`https://twitter.com/intent/tweet?text=${encodeURI(str)}`);
}

export function Confirmation({ closeModal }) {
  return (
    <>
      <div className="w-full font-mono font-bold text-center py-3.5 bg-gray-wash text-gray-secondary border-b border-gray-detail font-bold">
        Thank you for verifying!
      </div>
      <div className="pt-7 pb-4 px-8 text-center">
        <p className="font-mono">
          You can now add meanings or words to the glossary, and vote on meanings that resonate with you! <br />We're very excited for you to interact with Referents.
        </p>
        <div className="mt-5 mb-3 text-center">
          <Button
            onClick={generateSocial}
          >
            <TwitterIconBlack /> Share
          </Button>
          <Button
            onClick={closeModal}
          >
            Close
          </Button>
        </div>
      </div>
    </>
  )
}