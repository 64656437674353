import '../App.css';
import './about.css';
import Header from '../components/core/header';
import { Link } from 'react-router-dom';

function NotFound() {

  return (
    <>
      <div className="container max-w-5xl m-auto relative content-center">
        <div className="flex flex-col text-left my-20">
          <Header />
          <h1 className="">Hmm. You seem lost</h1>
          <p className="my-2">
            Want to head back <Link to="/" className='decorate'>home</Link> or read more <Link to="/about" className='decorate'>about us</Link>?
          </p>
        </div>
      </div>
    </>
  )
}

export default NotFound;