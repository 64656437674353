import { SERVER_URL } from ".";

// auth
export async function getAuth(signature) {
  return fetch(`${SERVER_URL}/auth`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      {
        signature,
      }
    ),
  }).then(jsonOrErrorHandler)
}

// verifies the signature
export async function verify(sig, handle) {
  return fetch(`${SERVER_URL}/verify/${cleanHandle(handle)}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${document.jwt}`
    },
    body: JSON.stringify(
      {
        signature: sig,
      }
    ),
  }).then(jsonOrErrorHandler)
}

// signs message
export async function sign(name, account, twitter, signature) {
  return fetch(`${SERVER_URL}/sign`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${document.jwt}`
    },
    body: JSON.stringify(
      {
        name: name,
        address: account,
        handle: cleanHandle(twitter),
        date: Date.now(),
        signature: signature,
      }),
  }).then(jsonOrErrorHandler)
}

/* ------------ HELPER FUNCTIONS ----------- */
export const jsonOrErrorHandler = async response => {
  const resp = response.json()
  if (response.ok) {
    return resp;
  }

  if (resp) {
    const error = await resp
    throw new Error(error.msg)
  } else {
    throw new Error('Internal server error')
  }
}

const cleanHandle = handle => handle[0] === "@" ? handle.substring(1) : handle;
