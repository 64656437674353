import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import "../App.css";
import { addUserData, db } from "../cache/cache";
import Header from "../components/core/header";
import Glossary from "../components/glossary";
import SignIn from "../components/proofOfHuman/signIn";
import SearchBar from "../components/search.js";
import AddWord from "../components/submit/addWord";
import { checkIfVerifiedUser } from "../scripts/arweave-queries";
import { getAuth } from "../scripts/proof-of-human";

function Main() {
  const [isVerified, setIsVerified] = useState(false);
  const [address, setAddress] = useState();
  const [signature, setSignature] = useState(undefined);
  const [handle, setHandle] = useState(undefined);
  const [name, setName] = useState(undefined);
  const [query, setQuery] = useState(undefined);
  const [{ data: accountData }] = useAccount({});

  const dbUserData = useLiveQuery(
    async () => {
      if (!address) { return; }
      const user = db.users.where('address').equals(address).first();
      return user;
    }, [address]);

  // set state based on dexie cached data
  useEffect(() => {
    if (dbUserData) {
      setSignature(dbUserData.signature);
      setHandle(dbUserData.handle)
      setName(dbUserData.name)
      setIsVerified(dbUserData.isVerified);
      getAuth(dbUserData.signature).then((res) => {
        document.jwt = res.jwt;
      }).catch(err => {
        console.log('error authorizing', err)
      })

      console.log("Hello", dbUserData.name, dbUserData.handle, dbUserData.signature)
    }
  }, [dbUserData]);

  // check if user is verified if not cached
  useEffect(() => {
    if (dbUserData) { return; }
    if (!address) {
      setSignature(undefined);
      return;
    }
    async function getSignature() {
      const { isVerified, signature, handle, name } = await checkIfVerifiedUser(address);
      if (isVerified) {
        setSignature(signature);
        setIsVerified(isVerified);
        addUserData(address, name, handle, signature, isVerified)
      } else {
        setSignature(undefined);
        setIsVerified(false);
      }
    }
    getSignature()
  }, [dbUserData, address])

  // set active address
  useEffect(() => {
    if (accountData) {
      setAddress(accountData.address)
    }
  }, [accountData]);

  // check if search params exist on page load
  useEffect(() => {
    const { search } = window.location;
    const searchParam = new URLSearchParams(search).get('search')?.toLowerCase();
    setQuery(searchParam);
  }, [])

  return (
    <div>
      <div className="h-screen flex">
        <div className="container max-w-6xl m-auto relative">
          <div className="top-action-container fixed z-10">
            <AddWord isVerified={isVerified} address={address} signature={signature} name={name} handle={handle} />
            <SignIn isVerified={isVerified} />
          </div>
          <Header setQuery={setQuery} />
          <SearchBar {...{ query, setQuery }} />
        </div>
      </div>

      <Glossary {...{ query, setQuery, name, handle, signature, address, isVerified }} />
    </div>
  )
};

export default Main;
