import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import './signIn.css';
import Modal from "react-modal";
import { useAccount, useConnect } from 'wagmi'
import { Sign, ConnectWallet, SocialProof, VerifyTwitter, Confirmation } from './signInModals';
import { useSignMessage } from 'wagmi';
import { getAuth, sign, verify } from '../../scripts/proof-of-human';
import { addUserData } from '../../cache/cache';

Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = '#555555aa';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderWidth: '0px',
    borderRadius: '0.75em',
    padding: '0',
    width: '80%'
  },
  overlay: { zIndex: 10 }
};

const abridgeAddress = (hex, length = 4) => {
  if (!hex) { return ''; }
  return `${hex.substring(0, length + 2)}…${hex.substring(
    hex.length - length
  )}`;
}

// modal states
const CONNECT_WALLET = 0;
const SIGN = 1;
const SOCIAL_PROOF = 2;
const VERIFICATION = 3;
const CONFIRMATION = 4;

function SignIn({ isVerified }) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(0);

  const [{ data: accountData }, disconnect] = useAccount({
    fetchEns: true,
  })
  const [{ data: connectData }, connect] = useConnect();

  useEffect(() => {
    const connectedProviderName = localStorage.getItem('provider');
    if (connectedProviderName) {
      const connectedProvider = connectData.connectors.find((connector) =>
        connector.name === connectedProviderName
      )
      connect(connectedProvider);
      setState(SIGN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDisconnect = () => {
    disconnect();
    localStorage.setItem('provider', undefined);
  }

  const [formData, setFormData] = useState();
  const [loading, setIsLoading] = useState(false);
  const [displayedError, setDisplayedError] = useState(false);

  // check if connected account is already verified (signed a message)
  useEffect(() => {
    if (!accountData || !accountData.address) { return; }
    if (isVerified) {
      setOpen(false);
      setState(CONFIRMATION);
    }
  }, [accountData, isVerified])

  const onConnectClick = () => {
    setOpen(true);
    setState(CONNECT_WALLET)
  };


  const {
    register,
    handleSubmit,
  } = useForm();

  const [, signMessage] = useSignMessage();

  const signWithoutVerifying = () => {
    setIsLoading(true);
    const { sig, handle, name, address } = formData;
    sign(name, address, handle, sig).then((result) => {
      displayedError('');
      setState(CONFIRMATION);
      setIsLoading(false);
      addUserData(address, name, handle, sig, false);
    }).catch((err) => {
      setDisplayedError(`An error occurred with signing to the blockchain. ${err.message}`);
      setState(VERIFICATION);
      setIsLoading(false);
    });
  };

  const handleTwitterVerifyAndSign = () => {
    const { sig, handle, name, address } = formData;
    setIsLoading(true);
    verify(sig, handle).then(() => { // verifying that they signed
      sign(name, address, handle, sig).then((result) => {
        setDisplayedError('');
        setState(CONFIRMATION);
        setIsLoading(false);
        addUserData(address, name, handle, sig, true);
      }).catch((err) => {
        setDisplayedError(`An error occurred with signing to the blockchain. ${err.message}`);
        setState(VERIFICATION);
        setIsLoading(false);
      })
    }).catch((err) => {
      setDisplayedError(`An error occurred. ${err.message}`);
      setIsLoading(false);
      setState(VERIFICATION);
    })
  }

  const onSubmit = (data) => {
    const message = `Hi ${data.name} (${accountData.ens?.name
      ? accountData.ens?.name
      : accountData.address}),
      \n\nplease sign for Referents.\n\n

      ${data.handle && `Twitter handle: ${data.handle}`}`;

    if (data.name === "") {
      setDisplayedError("Cannot sign with an empty name");
      return
    }

    setIsLoading(true);
    setDisplayedError(null);
    signMessage({ message })
      .then((sig) => {
        if (!sig.data) {
          setDisplayedError('There was a problem. Please try signing again.');
          setIsLoading(false);
          return;
        }
        getAuth(sig.data).then((res) => {
          document.jwt = res.jwt;
        }).catch(err => {
          console.log('error authorizing', err)
        });
        setFormData({
          sig: sig.data,
          name: data.name,
          handle: data.handle,
          address: accountData.address,
        })
        setIsLoading(false)
        setState(SOCIAL_PROOF)
      })
      .catch((err) => {
        setDisplayedError(err.message);
        setIsLoading(false);
      });
  };

  return (
    <div>
      {!accountData &&
        <button className='button' onClick={onConnectClick}>
          Connect wallet
        </button>}
      {accountData &&
        <div>
          {accountData.ens?.avatar &&
            <img
              src={accountData.ens?.avatar}
              alt="ENS Avatar"
              className='ens-avatar'
              onError={() => <></>}
            />}
          <div className='account-info'>
            <div>
              {accountData.ens?.name
                ? accountData.ens?.name
                : abridgeAddress(accountData.address)}
            </div>
            <div className='flex-column'>
              {state !== CONFIRMATION && <button className="mr-2" onClick={() => setOpen(true)}>Continue</button>}
              <button onClick={handleDisconnect}>Disconnect</button>
            </div>
          </div>
        </div>
      }
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        style={customStyles}
        contentLabel="sign-modal"
      >
        <div className="w-full h-full bg-gray-50">
          {state === CONNECT_WALLET && <ConnectWallet nextState={() => { setState(SIGN) }} />}
          {state === SIGN && <Sign {...{ handleSubmit, onSubmit, register, loading }} />}
          {state === SOCIAL_PROOF &&
            <SocialProof
              prevState={() => setState(SIGN)}
              nextState={() => setState(VERIFICATION)}
              {...{ loading, displayedError, signWithoutVerifying, setIsLoading, setDisplayedError, formData }}
            />}
          {state === VERIFICATION &&
            <VerifyTwitter
              prevState={() => setState(SOCIAL_PROOF)}
              {...{ loading, handleTwitterVerifyAndSign, displayedError }}
            />}
          {state === CONFIRMATION && <Confirmation closeModal={() => setOpen(false)} />}
          {displayedError &&
            <div className="my-8 text-center font-mono text-sm text-red-700">
              {displayedError}
            </div>}
        </div>
      </Modal>
    </div >
  )
}

export default SignIn;