import { SERVER_URL } from ".";
import { jsonOrErrorHandler } from "./proof-of-human";

/* --------- WRITE ---------- */
export async function submitWord(word, signature, name, handle) {
  return fetch(`${SERVER_URL}/word`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${document.jwt}`
    },
    body: JSON.stringify(
      {
        signature,
        word,
        name,
        handle,
      }
    ),
  }).then(jsonOrErrorHandler)
}

export async function submitMeaning(word, meaning, signature, name, handle, also, alsoLink) {
  return fetch(`${SERVER_URL}/meaning`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${document.jwt}`
    },
    body: JSON.stringify(
      {
        signature,
        word,
        meaning,
        name,
        handle,
        also,
        alsoLink
      }
    ),
  }).then(jsonOrErrorHandler)
}