import { useEffect, useRef, useState } from 'react';
import './vote.css';
import "../wordblock.css";
import Modal from "react-modal";
import { VoteModal } from './voteModal';
import { hashWordToMeaning } from '../../scripts/vote-utils';
import { useHistory } from "react-router-dom";
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../cache/cache';

Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = '#555555aa';

const customStyles = {
  content: {
    top: '10vh',
    left: '10vw',
    right: 'auto',
    bottom: 'auto',
    width: '80vw',
    marginRight: '-50%',
    borderWidth: '0px',
    borderRadius: '0.75em',
    padding: '0',
    overflow: 'auto',
  },
  overlay: { zIndex: 10 }
};

// modal states
const CAST_VOTE = 0;
// const ALREADY_VOTED = 1;

function Vote({ children, index, word, meaning, signature }) {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [wordHash, setWordHash] = useState();
  const [votes, setVotes] = useState();
  const [voteState, setVoteState] = useState(CAST_VOTE);

  const wordRef = useRef(null); //to be able to access the current one

  useEffect(() => {
    async function getHash() {
      const hash = await hashWordToMeaning(word, meaning.definition);
      setWordHash(hash);
    }
    if (word && meaning) {
      getHash()
    }
  }, [word, meaning]);

  const dbVoteData = useLiveQuery(
    async () => {
      if (!wordHash) { return; }
      const user = db.votes.where('wordHash').equals(wordHash).first();
      return user;
    }, [wordHash]);

  useEffect(() => {
    if (!dbVoteData) { return; }
    setVotes(dbVoteData.votes);
  }, [dbVoteData])

  const handleClick = () => {
    setOpen(true);
    history.replace("/word/" + kebabWord + '/' + wordHash);
  }

  const handleCLose = () => {
    setOpen(false);
    history.replace("/word/" + kebabWord);
  }

  // kebab case
  const kebabWord = word.toLowerCase().match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).join('-')

  return (
    <div className="meaning-card" key={index} ref={wordRef} >
      <button className="vote-container" onClick={handleClick}>
        <div className='vote-content'>
          {children}
        </div>
      </button>
      <Modal
        isOpen={open}
        onRequestClose={handleCLose}
        style={customStyles}
        contentLabel="sign-modal"
      >
        <VoteModal {...{ voteState, setVoteState, word, meaning, votes, signature }} />
      </Modal>
    </div >
  )
}

export default Vote;