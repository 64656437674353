import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import "./App.css";
import { Footer } from "./components/core/footer";
import { Banner } from "./components/core/banner";
import About from './pages/about';
import Main from './pages/main';
import NotFound from './pages/404';

function App() {
  return (
    <div className="App">
      <Banner />
      <Router>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/about" component={About} />
          <Route path="/word/:word" component={Main} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
