// db.js
import Dexie from 'dexie';

export const db = new Dexie('referents');

db.version(4).stores({
  users: '++id, address',
  votes: '++id, wordHash',
  submissions: '++id, word',
});

export async function addUserData(address, name, handle, signature, isVerified) {
  try {
    // Add the new user!
    const user = await db.users.where('address').equals(address).first();
    if (user) { return; }
    const id = await db.users.add({
      address,
      name,
      handle,
      signature,
      isVerified,
    });
    console.log(`add ${address}: ${id}`);
  } catch (error) {
    console.log(`Failed to add ${address}: ${error}`);
  }
}

export async function updateUserData(address, name, handle, signature, isVerified) {
  try {
    const user = await db.users.where('address').equals(address).first();
    if (!user) { return; }
    await db.users.update(user.id,
      {
        address,
        name,
        handle,
        signature,
        isVerified,
      });
  } catch (error) {
    console.log(`Failed to add ${address}: ${error}`);
  }
}

export async function addVoteData(address, wordHash, txId, voteValue) {
  if (!wordHash) { return; }
  try {
    const word = await db.votes.where('wordHash').equals(wordHash).first();
    if (!word) {
      await db.votes.add({
        wordHash,
        votes: [
          {
            address, txId, voteValue, wordHash
          }
        ]
      });
    } else {
      if (word.votes.filter(vote => vote.txId === txId).length === 0) {
        await db.votes.where('wordHash').equals(wordHash)
          .modify((word) => word.votes.push({ address, txId, voteValue, wordHash }));
      }
    }
  } catch (error) {
    console.log(`Failed to add vote ${wordHash}: ${error}`);
  }
}

export async function bulkSetVoteData(wordHash, votes) {
  try {
    const data = await db.votes.where('wordHash').equals(wordHash).first();
    if (!data) {
      await db.votes.add({
        wordHash,
        votes,
      });
    } else {
      if (data.votes.length >= votes.length) { return; }
      await db.votes.update(data.id,
        {
          wordHash,
          votes,
        });
    }
  } catch (err) {
    console.log(`Failed to bulkSetVoteData ${wordHash}: ${err}`);
  }
}

async function setWordData(word, meanings, txId) {
  try {
    const data = await db.submissions.where('word').equals(word).first();
    if (!data) {
      await db.submissions.add({
        word,
        txId,
        meanings,
      });
    } else {
      if (data.meanings.length >= meanings.length) { return; }
      await db.submissions.update(data.id,
        {
          word,
          txId,
          meanings,
        });
    }
  } catch (err) {
    console.log(`Failed to setWordData ${word}: ${err}`);
  }
}

// add new word if it doesn't exist
export async function addWord(word, txId) {
  try {
    const data = await db.submissions.where('word').equals(word).count();
    if (data <= 1) {
      await db.submissions.add({
        word,
        txId,
        meanings: [],
      });
    }
  } catch (err) {
    console.log(`Failed to setWordData ${word}: ${err}`);
  }
}

// append meaning to word
export async function addMeaningData(word, meaning, name, handle, txId, citation, citationLink) {
  try {
    await db.submissions.where('word').equals(word).modify((entry) => {
      entry.meanings.push({ definition: meaning, txId, name, handle, also: citation, alsoLink: citationLink });
    })
  } catch (err) {
    console.log(`Failed to setWordData ${word}: ${err}`);
  }
}

export async function bulkSetWordData(submissions) {
  try {
    submissions.forEach(async entry => {
      const { word, meanings, txId } = entry;
      await setWordData(word, meanings, txId);
    })
  } catch (err) {
    console.log(`Failed to bulkSetWordData: ${err}`);
  }
}

/* For moderation purposes */
export async function removeWordData(word) {
  try {
    await db.submissions.where('word').equals(word).delete();
  } catch (err) {
    console.log(`Failed to removeWordData ${word}: ${err}`);
  }
}
export async function removeMeaningData(word, txId) {
  try {
    await db.submissions.where('word').equals(word).modify((entry) => {
      entry.meanings = entry.meanings.filter((meaning) => meaning.txId !== txId)
    })
  } catch (err) {
    console.log(`Failed to removeMeaningData ${word} ${txId}: ${err}`);
  }
}