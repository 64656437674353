import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../App.css";
import { addVoteData, bulkSetWordData, db } from "../cache/cache";
import WordBlock from "../components/wordblock.js";
import { getVotes } from "../scripts/arweave-queries";
import { getSubmissions } from "../scripts/processSubmissions";

function Glossary({ query, setQuery, name, handle, signature, address, isVerified }) {
  const [data, setData] = useState();
  const glossaryRef = useRef(null); //to be able to access the current one
  const history = useHistory();
  const pathname = history.location.pathname.substring(1)
  const entryId = pathname.substring(2, pathname.indexOf('/'))

  // retrieve cached meanings
  useLiveQuery(
    async () => {
      const entries = [];
      await db.submissions.each(entry => entries.push(entry));
      entries.sort((a, b) => a.word.localeCompare(b.word));
      setData(filterEntries(entries, query));
    }, [query]);

  const filterEntries = (data, query) => {
    if (!query) {
      return data;
    }
    return data.reduce(function (filteredData, entry) {
      let meaningsWithQuery = entry.meanings.filter(x => x.definition.toLowerCase().includes(query));
      if (meaningsWithQuery.length > 0) {
        let filteredEntry = { word: entry.word, meanings: meaningsWithQuery };
        filteredData.push(filteredEntry);
      } else if (entry.word.toLowerCase().includes(query)) {
        filteredData.push(entry);
      }
      return filteredData;
    }, []);
  };

  const getEntries = () => {
    getSubmissions().then((entries) => {
      entries.sort((a, b) => a.word.localeCompare(b.word));
      bulkSetWordData(entries);
    })
      .then(() => {
        //animate once words have loaded
        // $('html, body').animate({
        //   scrollTop: $("#" + entryId).offset().top
        // }, 500);
      })
      .catch((err) => {
        console.log('error updating entries', err);
      })
  }

  useEffect(() => {
    if (query) {
      glossaryRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }, [query])

  useEffect(() => {
    getEntries(); // execute immediately on page load
    const interval = setInterval(() => {
      getEntries();
    }, 30000); // refresh every 30 seconds
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryId, query])

  const resetSearch = () => {
    setQuery('');
    history.replace(`/`);
  };

  /** GET votes */
  useEffect(() => {
    getBulkVotes(); // execute immediately on page load
    const interval = setInterval(() => {
      getBulkVotes();
    }, 30000); // refresh every 30 seconds
    return () => clearInterval(interval);
  }, [])

  const getBulkVotes = () => {
    getVotes()
      .then((votes) => {
        votes.forEach((vote) => {
          // update indexedDB
          // address: string, wordHash: string, txId: string, voteValue: number
          addVoteData(vote.VOTE_ADDR, vote.VOTE_MEANING, vote.ID, vote.VOTE_VALUE);
        })
      })
      .catch(err => {
        console.log(err);
      })
  }

  // P0 - update URL on scroll
  // P2 - TODO dont trigger a refresh if hitting the same url once onn the page

  return (
    <div className="container pb-12 max-w-5xl m-auto relative" ref={glossaryRef}>
      {(!data || data.length === 0) &&
        <div className="mt-10">
          We couldn't find what you're looking for :( In the meantime, <button onClick={resetSearch} className="decorate">clear your search</button> or check out our <Link to="/about" className="decorate">about page</Link>!
        </div>}
      {data?.map((item, index) =>
        <WordBlock
          key={index} item={item}
          lastItem={data[index - 1]}
          isVerified={isVerified}
          signature={signature}
          name={name} handle={handle}
          address={address}
        />)}
    </div>
  )
};

export default Glossary;
