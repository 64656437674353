import { Link, useHistory } from "react-router-dom";
import './header.css';

function Header({ setQuery }) {
  const history = useHistory();

  const onClickLogo = () => {
    if (setQuery) {
      setQuery(undefined);
      history.replace('/');
    }
  }

  return (
    <div className="header">
      <Link to="/" onClick={onClickLogo}>
        <img
          alt="Referents logo"
          src="/logoV2.svg"
          className="logo"
        />
      </Link>
      <div className="description">
        <p>
          <span className="italic">noun</span> <br></br>
          Referents is a collectively created glossary; it exists to help people understand the meaning of words used in and about web3. We dream of a world where language is not a barrier to being in a given space; Referents helps this happen by enabling mutual meaning-making. <Link to="/about" className="decorate">Learn more</Link>.
        </p>
      </div>
    </div>
  )
}

export default Header;