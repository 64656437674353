import '../App.css';
import './about.css';
import { useEffect } from "react";
import { createTelescopicTextFromBulletedList } from './telescopic'
import Header from '../components/core/header';

function About() {

  useEffect(() => {
    const teleHeadings = {
      "motivation-heading": `
      * Motivation
        * why did the humans behind this lift a finger and start building?`,
      "intentions-heading": `
      * Intentions
        * This is a tool. It’s instrumental, we’re in part trying to move from a -> b here. This is our theory of change.`,
      "how-to-heading": `
      * How to use Referents
        * here's what can you do with this thing`,
      "made-this-heading": `
      * How we made this
        * the technology we chose and why we did so`,
      "faq-heading": `
      * FAQ
        * these are questions asked frequently`
    }

    for (const id of Object.keys(teleHeadings)) {
      const node = createTelescopicTextFromBulletedList(teleHeadings[id]);
      const container = document.getElementById(id);
      container.innerHTML = ""; //remove the default text that's there as a placeholder
      container.appendChild(node);
    }
  });

  return (
    <>
      <div className="container max-w-5xl m-auto relative content-center">
        <div className="flex flex-col text-left my-20 mx-10">
          <Header />
          <h1 className="">About this Artifact</h1>
          <p className='underline'>(This artifact, and the about page below, are still in beta and evolving based on feedback.)</p>
          <p className="my-2">
            Referents is a collectively created glossary; it exists to help people understand the meaning of words used in and about web3. We dream of a world where language is not a barrier to being in a given space; Referents helps this happen by enabling mutual meaning-making.
          </p>
          <h2 className="mt-4" id='motivation-heading'>Motivation</h2>
          <p className="my-2">
            Referents was inspired by the convergence of a few ideas. <br /><br />

            The opportunity <b>to do things with words</b>. We’re fascinated by words, and the space known as web3 is a great place to explore them right now. New words and meanings are emerging every day fueled by the new technologies, economics, and worlds being built here.<br /><br />

            A recognition that <b>web3 is inaccessible and unwelcoming for many; a major cause of this is <i>inaccessible</i> language</b>. We believe web3 should not be inaccessible to anybody on the basis of not knowing what words mean. The discourse of many web3 spaces runs on exclusivity, in-group-ness, and self-referentiality (as one might expect from how quickly the space is emerging).<br /><br />

            A desire for a low-stakes environment to experiment with <b>governance mechanisms</b> in web3, as opposed to a higher-stakes setting like DAO governance. This was directly inspired by the essay <a className='underline' href='https://baby.mirror.xyz/O7a922A-9zT4C4UwssRExkftdHywJ-13sR2rxQ-t__k'>Turing-Complete Governance</a>.<br/>
          </p>
          <h2 className="mt-4" id='intentions-heading'>Intentions</h2>
          <p className="my-2">
            Referents hopes to become an online stage for meaning-making, a space where many meaning-worlds can coexist and evolve.
            <br /><br />
            Our digital identities belong to communities and identities that are emerging, morphing, and collapsing more frequently than ever. Each of us occupies many worlds of meaning; those in web3 are particularly small in population and scoped in vocabulary. For any speech act we make or encounter in web3, our meaning-worlds are less likely to overlap, and this gap can cause us to not know what each other means, or worse, misunderstanding without realizing.
            <br /><br />
            For web3, Referents will make the space more accessible, <b>where <i>language</i> can be less of a barrier</b> to newcomers. This is particularly needed given the linguistic volatility and exclusivity within web3.
            <br /><br />
            For communities, those whose speakers and listeners inhabit this stage will form a <a href='https://web.stanford.edu/~eckert/csofp.html'>community of practice</a>, a group of people who with mutual awareness contribute to Referents, cite its meanings, and see legitimacy in citations they encounter.
            <br /><br />
            For individuals, people can meaning-make on Referents: find words to put to their experiences, play with concepts, illuminate formerly illegible oceans of intuition.
            <br /><br />
            Words and meanings have power; semiotic land grabs have power. Hopefully Referents makes this power more accessible and participatory.
            <br /><br />
            Related readings: <a className='underline' href='https://play.jzhao.xyz/new-words'>on Play</a>, <a className='underline' href='https://otherinter.net/research/lore/'>The Lore Zone</a>
          </p>
          <h2 className="mt-4" id='how-to-heading'>How to use Referents</h2>
          <p className="my-2">
            There are three main ways to use Referents:
          </p>
          <ul className='list-disc pl-6'>
            <li><b>Browse</b> - Look up words if you encounter them and want to know what others mean by them. </li>
            <li><b>Vote</b> - Upvote or downvote a meaning to change their display rank.</li>
            <li><b>Contribute</b> - You can help us curate this resource by adding words or meanings. We encourage you to add a new word especially if you are curious about how other people make sense of that word, and to add a new meaning especially if it represents a different perspective on the word to existing meanings.</li>
          </ul>

          <br />
          If you would like to contribute a meaning to Referents, please follow this style guide:
          <div className='border-black border-solid border p-2'>
            This is intentionally prescriptive and should be seen as aspirational, instead of a requirement. Meanings should:
            <ul className='list-disc pl-6'>
              <li>Include at minimum a definition and an example (what it means, and how to use it in context).</li>
              <li>Assume no prior understanding of web3 knowledge, but high interest to learn (e.g. willingness to read links!)</li>
              <li>Be able to stand alone and have utility; readers should come away feeling they know what the word means and how to use it.</li>
              <li>Be longer than three sentences.</li>
              <li>Not feel generalized. Entries need meanings that are actually meant when people (you!) make speech acts, not an academic abstraction that nobody is really referring to. Put differently: if a word has multiple common meanings, <b>each meaning in Referents should clearly fall short of covering multiple of them.</b></li>
            </ul>
          </div>

          <h2 className="mt-4" id='made-this-heading'>How we made Referents</h2>
          <p className="my-2">
            <i>Arweave</i><br />
            We initially had a more web2 flow, using no-code forms like Coda to receive submissions. We later pivoted to storing everything on-chain because it’s a smoother flow, and leans into the ethos of web3 experimentation. We chose Arweave because of its relatively robust SDK and inexpensive fees. However, this setup does not come without challenges – these include content moderation, key security and implementing reliable  reads and writes as well.<br /><br />

            <i>tmm/wagmi</i><br />
            We were deciding between a couple different web3 Javascript packages that could abstract away the complexities involved with wallet connection and decided  on <a href='https://github.com/tmm/wagmi'>tmm/wagmi</a> for its extensive hooks and ease of use.<br /><br />

            <i>Reusing Verses code and patterns: </i><br />
            We intentionally reused and remixed code and patterns from the Verses community where possible For example, the Verses community is well versed with Arweave and its challenges through prior drops like <a className='underline' href='http://www.pluirverse.world'>Pluriverse</a> and <a className='underline' href='http://www.interdependence.online'>Interdependence</a>. We’re using a <a className='underline' href='https://www.npmjs.com/package/ar-wrapper'>lightweight arweave wrapper</a> created  by Verses’ own  Jacky Zhao (its opensource too!) that makes Arweave writes easier to execute and increases reliability through a polling system. <br />
            See: <a className='underline' href='https://www.npmjs.com/package/ar-wrapper'> ar-wrapper</a>, <a className='underline' href='https://www.npmjs.com/package/legacy-xyz'>legacy-xyz</a>, <a className='underline' href='https://github.com/verses-xyz/pluriverse'>Verses sign-in pattern</a>, <a className='underline' href='https://github.com/jackyzha0/telescopic-text'>telescoping text</a>
            <br /><br />

            <i>React</i><br/>
            This was more a default choice than an intentional choice. We started out the prototype with a create-react-app application and just stuck with it. In hindsight, going with a lighter-weight framework like next would have probably been a better choice and provided better search engine optimization. <br /><br />

            <i>Design Decisions</i><br />
            We'd like both to curate useful meanings (which requires some notion of ranking), and to realize the plurality and validity of many meanings for a given word. Hence we have tried to make Referents less hierarchical than standard references, which usually display meanings linearly in rank order. Although meanings for each word are ranked using votes, we place them in "cards" across the page for a more pluralistic visual effect.<br /><br />

          </p>
          <h2 className="mt-4" id='faq-heading'>FAQ</h2>
          <p className="my-2">
            <i>Is there an airdrop, token, NFT, or mint associated with this project?</i><br/>
            No. <br /><br />
            
            <i>Are there plans to expand to other languages or modalities?</i><br/>
            Yes, this is on our roadmap!<br /><br />
          </p>
        </div>
      </div>
    </>
  )
}

export default About;