/* The following are all unauthed read requests */
import { SERVER_URL } from ".";
import { jsonOrErrorHandler } from "./proof-of-human";

/* document types are
- signature
- verification
- vote
- word
- meaning
*/

// voteId is the hash of meaning<>word
export async function getVotes() {
  try {
    return fetch(`${SERVER_URL}/votes`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(jsonOrErrorHandler)
  } catch (err) {
    return []
  }
}

export async function getVotesForMeaning(meaningHash) {
  try {
    const req = fetch(`${SERVER_URL}/votes?meaningHash=${meaningHash}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(jsonOrErrorHandler);

    return req;
  } catch {
    return []
  }
}

export async function checkIfVerifiedUser(address) {
  const req = fetch(`${SERVER_URL}/userVerified?userAddress=${address}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(jsonOrErrorHandler).catch((err) => {
    console.error('checkIfVerifiedUser', err);
    return { isVerified: false }
  });
  return req;
}

// getWords - returns a map
export async function getWords() {
  try {
    const req = await fetch(`${SERVER_URL}/words`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(jsonOrErrorHandler)
    return req;
  } catch (err) {
    return {};
  }
}

export async function getMeanings() {
  try {
    const req = await fetch(`${SERVER_URL}/meanings`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(jsonOrErrorHandler);

    return req;
  } catch (err) {
    return []
  }
}

