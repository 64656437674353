import { ContentState, Editor, EditorState } from 'draft-js';
import Modal from "react-modal";
import { useState } from 'react';
import './addWord.css';
import { useSnackbar } from 'react-simple-snackbar'
import { submitMeaning } from '../../scripts/submit';
import { useSignMessage } from 'wagmi';
import ScaleLoader from "react-spinners/ScaleLoader";
import { addMeaningData } from '../../cache/cache';

Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = '#555555aa';

const customStyles = {
  content: {
    top: '10vh',
    left: '10vw',
    right: 'auto',
    bottom: 'auto',
    width: '80vw',
    marginRight: '-50%',
    borderWidth: '0px',
    borderRadius: '0.75em',
    padding: '0',
  },
  overlay: { zIndex: 10 }
};

const MAX_MEANING_LENGTH = 900;
const MAX_CITATION_LENGTH = 100;


function AddMeaning({ signature, word, name, handle, isVerified }) {
  const [alert, setAlert] = useState();
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [citationState, setCitationState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [citationLinkState, setCitationLinkState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [, signMessage] = useSignMessage();

  const [openSnackbar] = useSnackbar({
    position: 'top-center',
    style: {
      backgroundColor: '#FFFFFF',
      border: '2px solid #878682',
      color: '#383838',
      fontFamily: 'Menlo, monospace',
      fontSize: '20px',
      textAlign: 'center',
    },
    closeStyle: {
      color: '#383838',
      fontSize: '16px',
    },
  })

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (!signature || signature.length === 0) {
      openSnackbar("Connect your wallet and verify proof-of-human to add a meaning.")
    } else if (!isVerified) {
      openSnackbar("Please verify proof-of-human to add a meaning.")
    } else {
      setOpen(true);
    }
  };

  const generateMessage = (meaning) => {
    return `Hi ${name} @${handle}. Please sign to confirm your contribution to Referents:\n${word}\n\n${meaning}`
  }

  function validateUrl(value) {
    return value.startsWith('/') || /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  const handleSubmit = async () => {
    setLoading(true);

    // validate meaning
    const meaning = editorState.getCurrentContent().getPlainText();
    if (meaning.length < 1 || meaning.length > MAX_MEANING_LENGTH || (!/^[a-zA-Z0-9]/.test(meaning))) {
      setAlert('Invalid meaning');
      setLoading(false);
      return;
    }

    // check if citation was filled
    const citation = citationState.getCurrentContent().getPlainText();
    const citationLink = citationLinkState.getCurrentContent().getPlainText();
    if (citation || citationLink) {
      // validate citation
      if (citationLink && !citation) {
        setAlert('Please add text to display.');
        setLoading(false);
        return;
      }

      if (citation.length > MAX_CITATION_LENGTH) {
        setAlert('Maximum length of a citation is 100 characters');
        setLoading(false);
        return;
      }

      if (citationLink && !validateUrl(citationLink)) {
        setAlert('Please submit a valid url');
        setLoading(false);
        return;
      }
    }
    setAlert(undefined);
    console.log('Submitting', meaning);
    try {
      const signature = await signMessage({ message: generateMessage(meaning.trim()) });
      if (!signature.data) {
        setAlert('User denied message signature');
        setLoading(false);
        return;
      }
      const res = await submitMeaning(word, meaning.trim(), signature.data, name, handle, citation, citationLink);
      console.log('Submitted meaning for', word, name, handle, res);
      addMeaningData(word, meaning.trim(), name, handle, res.txID, citation, citationLink); // optimistically add word data
      openSnackbar("Thank you for your submission!")
      setOpen(false);
      setEditorState(EditorState.push(editorState, ContentState.createFromText('')))
    } catch (err) {
      setAlert("We had an error submitting a new meaning. Please try again.")
      console.log('Error submitting meaning', err)
    }
    setLoading(false);
  };

  return (
    <>
      <button
        className="see-more inline-block bg-gray-100 hover:bg-gray-200 cursor-pointer px-3 py-0.5 mt-5 ml-2"
        onClick={handleClick}>
        <span className="font-sans add-word">
          +
        </span>
      </button>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        style={customStyles}
        contentLabel="sign-modal"
      >
        <div className='p-10'>
          <div className='flex-space-between mb-5'>
            <div>
              <h1>{word.charAt(0).toUpperCase() + word.slice(1)}</h1>
              <p>Add a new meaning for {word.charAt(0).toUpperCase() + word.slice(1)}.</p>
            </div>
            <div>
              Signing as <a className="decorate" href={`https://twitter.com/${handle}`} target="_blank" rel="noreferrer">{name}</a>
            </div>
          </div>
          <p className="italic text-sm text-gray-500">Meaning</p>
          <div className='text-grid'>
            <div>
              <div className='editor-box mb-5'>
                <p className="italic text-xs text-gray-500">{editorState.getCurrentContent().getPlainText().length}/{MAX_MEANING_LENGTH}</p>
                <Editor
                  editorState={editorState}
                  onChange={setEditorState}
                />
              </div>
              <p className="italic text-sm text-gray-500">Citation (optional)</p>
              <div className='citation-box'>
                <p className="italic text-xs text-gray-500">Text to display – {citationState.getCurrentContent().getPlainText().length}/{MAX_CITATION_LENGTH}</p>
                <Editor
                  editorState={citationState}
                  onChange={setCitationState}
                />
              </div>
              <div className='citation-box mt-5'>
                <p className="italic text-xs text-gray-500">Link</p>
                <Editor
                  editorState={citationLinkState}
                  onChange={setCitationLinkState}
                />
              </div>
            </div>
            <div className='preview'>
              <div className='mb-4'>
              <p>{editorState.getCurrentContent().getPlainText()}</p>
              {citationState.getCurrentContent().hasText() && <p className="text-gray-400 text-sm mt-2">
                See also: <span className={citationLinkState.getCurrentContent().hasText() ? 'decorate' : ''}>{citationState.getCurrentContent().getPlainText()}</span>
              </p>}
              </div>
              <div className='submitter'>
                Submitted by: {name}
              </div>
            </div>
          </div>
          <div>
            {alert &&
              <div className='alert'>
                {alert}
              </div>}
            {!loading && <button className='button mr-2 mt-5 mb-5 float-right' onClick={handleSubmit}>
              Submit
            </button>}
            {loading && <div className="mr-2 mt-5 mb-5 float-right"><ScaleLoader color="gray" /></div>}
          </div>
        </div>
      </Modal>
    </>
  )

}

export default AddMeaning;
