import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4'

import { Provider, defaultChains } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { WalletLinkConnector } from 'wagmi/connectors/walletLink'
import SnackbarProvider from 'react-simple-snackbar';

const connectors = [
  new WalletLinkConnector({
    options: {
      appName: 'Referents.xyz',
      jsonRpcUrl: 'https://mainnet.infura.io/v3/8729469fbd7643838349e7c009e1a6c4',
    },
  }),
  new InjectedConnector({ chains: defaultChains }),
  new WalletConnectConnector({
    chains: defaultChains,
    options: {
      infuraId: '8729469fbd7643838349e7c009e1a6c4',
      qrcode: true,
    },
  }),
]
ReactGA.initialize('G-QJQZRGQC68');

ReactDOM.render(
  <React.StrictMode>
    <Provider connectors={connectors}>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
