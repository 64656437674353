import { useEffect, useRef, useState } from "react";
import "./wordblock.css";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import Vote from "./voting/vote";
import AddMeaning from "./submit/addMeaning";

function WordBlock({ item, signature, name, handle, address, isVerified }) {
    const [collapsed, setCollapsed] = useState(true);
    const wordsAboveFold = 3;
    const wordRef = useRef(null); //to be able to access the current one
    const path = useHistory().location.pathname.split('/');
    // kebab-case
    const kebabWord = item.word.toLowerCase().match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).join('-');

    useEffect(() => {
        if (path.length > 2 && path[2] === kebabWord) {
            wordRef.current.scrollIntoView();
            setCollapsed(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="pt-14 pb-10 border-t border-gray-200 text-left leading-snug flex" id={item.word} ref={wordRef} >
            <div className="flex-1 font-h2 pr-4">
                <div className="font-semibold">
                    <Link to={"/word/" + kebabWord}>
                        <p className="word-title">{item.word.charAt(0).toUpperCase() + item.word.slice(1)}</p>
                    </Link>
                </div>
                <div className="mt-5">
                    <div className="grid-container">
                        {(collapsed ? item.meanings.slice(0, wordsAboveFold) : item.meanings).map((meaning, index) =>
                            <Vote
                                key={index}
                                index={index}
                                word={item.word}
                                meaning={meaning}
                                signature={signature}
                            >
                                <div className="font-serif text-lg leading-snug">
                                    {typeof meaning === 'string' ? meaning : meaning.definition}
                                    <p className="text-gray-400 text-sm mt-4">
                                        {meaning.also && <>
                                            See also:{' '}
                                            <a
                                                className={meaning.alsoLink ? 'decorate' : ''}
                                                href={meaning.alsoLink ?
                                                    (meaning.alsoLink.startsWith('/') ?
                                                        `/word${meaning.alsoLink}`
                                                        : `${meaning.alsoLink}`)
                                                    : ''}
                                                target={meaning.alsoLink ?
                                                    (meaning.alsoLink.startsWith('/') || meaning.alsoLink.includes('referents.xyz')?
                                                        ``
                                                        : `_blank`)
                                                    : ''}
                                                rel='noreferrer'
                                            >
                                                {meaning.also}
                                            </a>
                                        </>}
                                    </p>
                                    <div className="clear-both"></div>
                                </div>
                            </Vote>
                        )}
                    </div>
                </div>
                <div className="action">
                    {item.meanings.length > wordsAboveFold &&
                        <button
                            className="see-more inline-block bg-gray-100 hover:bg-gray-200 cursor-pointer px-3 py-0.5 mt-5"
                            onClick={() => setCollapsed(!collapsed)}>
                            {collapsed ? 'SEE MORE' : 'Less'}
                        </button>
                    }
                    <AddMeaning address={address} signature={signature} word={item.word} name={name} handle={handle} isVerified={isVerified} />
                </div>
            </div>
        </div>
    );
}

export default WordBlock;
