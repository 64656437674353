import keccak256 from 'keccak256';
import { SERVER_URL } from '.';
import { jsonOrErrorHandler } from './proof-of-human';

const cleanText = (text) => {
  return text.trim().toLowerCase();
};

/* --------- WRITE ---------- */
export async function vote(address, hash, vote) {
  return fetch(`${SERVER_URL}/vote`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${document.jwt}`
    },
    body: JSON.stringify(
      {
        address,
        hash: hash,
        voteValue: vote,
      }
    ),
  }).then(jsonOrErrorHandler)
}


/* --------- HELPERS ---------- */
export async function voteMeaning(word, meaning, address, voteValue) {
  if (voteValue !== -1 && voteValue !== 1) {
    return "invalid vote";
  }
  const meaningHash = await hashWordToMeaning(word, meaning);
  console.log('vote', voteValue, meaningHash);
  return await vote(address, meaningHash, voteValue);
}

// hash word <> meaning pair with keccak256
export async function hashWordToMeaning(word, meaning) {
  return keccak256(`${cleanText(word)}-${cleanText(meaning)}`).toString('hex');
}
