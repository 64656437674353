import { useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import ScaleLoader from "react-spinners/ScaleLoader";
import { useAccount } from 'wagmi';
import { addVoteData } from '../../cache/cache';
import { voteMeaning } from '../../scripts/vote-utils';
import './vote.css';
import { CastVoteButtonGroup, DownvoteTriangle, UpvoteTriangle } from './voteButtons';

// modal states
const CAST_VOTE = 0;
const ALREADY_VOTED = 1;

const VoteListItem = ({ txId, voteValue }) => {
  return (
    <div className='vote-row'>
      {txId && <p>TXID: <a className="" href={`https://arweave.net/tx/${txId}`} target="_blank" rel="noreferrer">
        {txId.substring(0, 5)}...{txId.slice(-5)}</a></p>}
      {voteValue === "1" && <UpvoteTriangle />}
      {voteValue === "-1" && <DownvoteTriangle />}
    </div>
  )
}

const VoteList = ({ votes }) => {
  const [upvotes, setUpvotes] = useState(0);
  const [downvotes, setDownvotes] = useState(0);

  // count votes
  useEffect(() => {
    let ups = 0;
    let downs = 0;
    if (votes && votes.length > 0) {
      votes.forEach((vote) => {
        if (vote.voteValue === 1) {
          ups++;
        } else if (vote.voteValue === -1) {
          downs++;
        }
      })
      setUpvotes(ups);
      setDownvotes(downs);
    }
  }, [votes]);

  return (
    <div className="vote-list-container">
      <div className="vote-list-items">
        {votes && votes.length > 0 && votes.map((vote, idx) => {
          return <VoteListItem key={idx} txId={vote.txId} voteValue={vote.voteValue} />
        })}
      </div>
      <div className="vote-count center">
        <UpvoteTriangle />
        <span className='ml-2 mr-3'>{upvotes}</span>
        <DownvoteTriangle />
        <span className='ml-2 mr-3'>{downvotes}</span>
      </div>
    </div>
  )
}

export function VoteModal({ voteState, setVoteState, word, meaning, votes, signature }) {
  const [loading, setLoading] = useState(false);
  const [voteTxId, setVoteTxId] = useState("");
  const [votedValue, setVotedValue] = useState(0);

  const [{ data: accountData }] = useAccount({});
  const [openSnackbar] = useSnackbar({
    position: 'top-center',
    style: {
      backgroundColor: '#FFFFFF',
      border: '2px solid #878682',
      color: '#383838',
      fontFamily: 'Menlo, monospace',
      fontSize: '20px',
      textAlign: 'center',
    },
    closeStyle: {
      color: '#383838',
      fontSize: '16px',
    },
  });

  useEffect(() => {
    if (!accountData || !votes || votes.length === 0) { return; }
    votes.forEach((vote) => {
      if (vote.address === accountData.address) {
        setVoteState(ALREADY_VOTED)
        setVotedValue(vote.voteValue)
        setVoteTxId(vote.txId)
      }
    })
  }, [accountData, votes, setVoteState])

  const handleUpvote = async () => {
    setLoading(true);
    try {
      const res = await voteMeaning(word, meaning.definition, accountData.address, 1);
      openSnackbar("Upvote submitted. Votes are saved on the Arweave blockchain and can a few minutes to update.")
      setTimeout(() => setVoteState(ALREADY_VOTED), 5000)
      console.log(res);
      addVoteData(accountData.address, res.wordHash, res.txID, 1); // optimistically add vote data
      setLoading(false);
    } catch (err) {
      openSnackbar("We had an issue processing your vote. Please try again.")
      console.error('upvote error', err)
      setLoading(false);
    }
  };

  const handleDownvote = async () => {
    setLoading(true);
    try {
      const res = await voteMeaning(word, meaning.definition, accountData.address, -1);
      openSnackbar("Downvote submitted. Votes are saved on the Arweave blockchain and can a few minutes to update.")
      setTimeout(() => setVoteState(ALREADY_VOTED), 5000)
      console.log(res);
      addVoteData(accountData.address, res.wordHash, res.txID, 1); // optimistically add vote data
      setLoading(false);
    } catch (err) {
      openSnackbar("We had an issue processing your vote. Please try again.")
      console.error('downvote error', err)
      setLoading(false);
    }
  }

  return (
    <div className="vote-modal w-full h-full">
      <h2 className="px-5 pt-3.5">
        {word.charAt(0).toUpperCase() + word.slice(1)}
      </h2>
      <div className="grid">
        <div className="meaning-box">
          <div className='text'>
            <p>
              {typeof meaning === 'string' ? meaning : meaning.definition}
            </p>
            {meaning.also && <p className="see-also text-gray-400 text-sm mt-5">
              See also:{' '}
              <a
                className={meaning.alsoLink ? 'decorate' : ''}
                href={meaning.alsoLink ?
                  (meaning.alsoLink.startsWith('/') ?
                    `/word${meaning.alsoLink}`
                    : `${meaning.alsoLink}`)
                  : ''}
                target="_blank"
                rel='noreferrer'
              >
                {meaning.also}
              </a>
            </p>}
          </div>
          <div className='submitter'>
            {(meaning && meaning.name) ?
              <div>Submitted by: {meaning.name}
                <a className=""
                  href={`https://arweave.net/tx/${meaning.txId}`}
                  target="_blank" rel="noreferrer"> (<span className='monospace'>{meaning.txId.slice(0, 4)}...{meaning.txId.slice(-4)}</span>)</a>

              </div> :
              "Anonymous submission"}

          </div>
        </div>
        <div className="vote-box">
          <p className='vote-label'>
            Recent votes
          </p>
          <VoteList votes={votes} />
        </div>
      </div>
      {
        voteState === CAST_VOTE &&
        <div>
          <div className='action-area'>
            {signature ?
              <div className='action-text'>
                Cast your vote on this meaning
              </div> :
              <div className='action-text'>Connect your wallet and sign a proof-of-human to vote.</div>
            }
            {!loading && <CastVoteButtonGroup disabled={!signature} handleUpvote={handleUpvote} handleDownvote={handleDownvote} />}
            {loading && <ScaleLoader color="gray" height={12} width={3} />}
          </div>
        </div>
      }
      {
        voteState === ALREADY_VOTED &&
        <div className='action-area'>
          <div className='action-text'>
            You have voted - txID:
          </div>
          <div className='action-text'>
            <a className="" href={`https://arweave.net/tx/${voteTxId}`} target="_blank" rel="noreferrer">
              {voteTxId ? `${voteTxId.substring(0, 5)}...${voteTxId.slice(-4)}` : ''}
            </a>
          </div>
          {votedValue > 0 && <UpvoteTriangle />}
          {votedValue < 0 && <DownvoteTriangle />}
        </div>
      }
    </div >
  )

}