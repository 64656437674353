import { ContentState, Editor, EditorState } from 'draft-js';
import { getDefaultKeyBinding } from 'draft-js';
import Modal from "react-modal";
import { useState } from 'react';
import './addWord.css';
import { useSnackbar } from 'react-simple-snackbar'
import { submitWord } from '../../scripts/submit';
import { useSignMessage } from 'wagmi';
import ScaleLoader from "react-spinners/ScaleLoader";
import { addWord, db } from '../../cache/cache';

Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = '#555555aa';

const customStyles = {
  content: {
    top: '10vh',
    left: '10vw',
    right: 'auto',
    bottom: 'auto',
    width: '80vw',
    marginRight: '-50%',
    borderWidth: '0px',
    borderRadius: '0.75em',
    padding: '0',
  },
  overlay: { zIndex: 10 }
};

function AddWord({ isVerified, signature, name, handle }) {
  const [alert, setAlert] = useState();
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [, signMessage] = useSignMessage();

  const [openSnackbar] = useSnackbar({
    position: 'top-center',
    style: {
      backgroundColor: '#FFFFFF',
      border: '2px solid #878682',
      color: '#383838',
      fontFamily: 'Menlo, monospace',
      fontSize: '20px',
      textAlign: 'center',
    },
    closeStyle: {
      color: '#383838',
      fontSize: '16px',
    },
  })

  const [open, setOpen] = useState(false);

  /* Text editor configs */
  const keyBindingFn = (e) => {
    if (e.keyCode === 13 /* `Enter` key */) {
      return 'submit';
    }
    return getDefaultKeyBinding(e);
  }
  const handleKeyCommand = (command) => {
    if (command === 'submit') {
      handleSubmit();
      return 'handled';
    }
    return 'not-handled';
  }

  const handleClick = () => {
    if (!signature) {
      openSnackbar("Connect your wallet and verify proof-of-human to add an entry.")
    } else if (!isVerified) {
      openSnackbar("Please verify proof-of-human to add a meaning.")
    } else {
      setOpen(true);
    }
  };

  const generateMessage = (word) => {
    return `Hi ${name} @${handle}. Please sign to confirm your submission to Referents: ${word}
    `
  }

  const handleSubmit = async () => {
    setLoading(true);
    const word = editorState.getCurrentContent().getPlainText();
    if (word.length < 1 || word.length > 25 || (!/^[a-zA-Z0-9]/.test(word))) {
      setAlert('Invalid word');
      setLoading(false);
      return;
    }
    setAlert(undefined);
    console.log('Submitting', word);
    // if word already exists don't submit

    const dbWord = await db.submissions.where('word').equals(word.trim().toLowerCase()).count();

    if (dbWord > 0) {
      setAlert(`${word} has already been submitted.`);
      setLoading(false);
      return;
    }

    try {
      const signature = await signMessage({ message: generateMessage(word.trim()) });
      if (!signature.data) {
        setAlert('User denied message signature');
        setLoading(false);
        return;
      }
      const res = await submitWord(word.trim(), signature.data, name, handle);
      console.log('Submitted word', res)
      addWord(word.trim().toLowerCase(), res.txId); // optimistic cache
      openSnackbar("Thank you for your submission!")
      setOpen(false);
      setEditorState(EditorState.push(editorState, ContentState.createFromText('')))
    } catch (err) {
      setAlert("We had an error submitting a new word. Please try again.")
      console.log('Error submitting word', err)
    }
    setLoading(false);
  };

  return (
    <>
      <button className='button mr-2' onClick={handleClick}>
        Add a new word
      </button>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        style={customStyles}
        contentLabel="sign-modal"
      >
        <div className='p-10'>
          <div className='flex-space-between'>
            <div>
              <h1>Add a new word</h1>
              <p>Please submit a new word.</p>
            </div>
            <div>
              Signing as <a className="decorate" href={`https://twitter.com/${handle}`} target="_blank" rel="noreferrer">{name}</a>
            </div>
          </div>
          <div className='editor-box mt-5'>
            <Editor
              editorState={editorState}
              onChange={setEditorState}
              handleKeyCommand={handleKeyCommand}
              keyBindingFn={keyBindingFn}
            />
          </div>
          <div>
            {alert &&
              <div className='alert'>
                {alert}
              </div>}
            {!loading && <button className='button mr-2 mt-5 mb-5 float-right' onClick={handleSubmit}>
              Submit
            </button>}
            {loading && <div className="mr-2 mt-5 mb-5 float-right"><ScaleLoader color="gray" /></div>}
          </div>
        </div>
      </Modal>
    </>
  )

}

export default AddWord;
