import { useHistory } from 'react-router-dom';
import './search.css';

function SearchBar({ query, setQuery }) {
  const path = useHistory();

  const onSubmit = (e) => {
    e.preventDefault();
    path.replace(`/?search=${e.target.elements.search.value}`);
    setQuery(e.target.elements.search.value);
  };

  return (
    <form className="pb-6" onSubmit={onSubmit} action="/" method="get">
      <label htmlFor="header-search">
        <span className="visually-hidden">Search meanings and entries</span>
      </label>
      <input
        className="search-bar px-4"
        autoComplete="off"
        type="text"
        id="header-search"
        placeholder="Search meanings and entries"
        name="search"
        defaultValue={query}
        style={{ width: "35%" }}
      />
      <button
        type="submit"
        className="search-button"
      >
        SEARCH
      </button>
    </form>
  )
}
export default SearchBar;
