import { getMeanings, getWords } from "./arweave-queries";
import { removeMeaningData, removeWordData } from '../cache/cache.js';


async function processSubmissions(words, entries, blocklist) {
  if (!entries) { return words };

  blocklist.words.forEach((word) => {
    delete words[word]
    removeWordData(word)
  })

  entries.forEach(entry => {
    // word must exist and be previously submitted
    const key = entry.word && entry.word.toLowerCase();

    if (blocklist.meanings[entry.txId]) {
      removeMeaningData(key, entry.txId);
      return;
    }

    if (words[key] && words[key].meanings) {
      words[key].meanings.push({
        definition: entry.meaning,
        name: entry.name,
        handle: entry.handle,
        txId: entry.txId,
        also: entry.also,
        alsoLink: entry.alsoLink,
      });
    }
  });

  // convert to list
  const wordList = Object.entries(words).map(([key, meanings]) => {
    return ({
      word: key,
      ...meanings,
    })
  });

  return wordList;
}

export async function getSubmissions() {
  var blocklist = require('../moderation/blocklist.json');
  const meanings = await getMeanings(); // returns a list
  const words = await getWords(); // returns  map
  const processed = await processSubmissions(words, meanings, blocklist);
  return processed;
}